import React from 'react';
import { FaFacebookF, FaLinkedinIn } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { Link } from 'react-router-dom';
import Tilty from 'react-tilty';


const BannerFour = () => {
    return (
        <div className="banner banner-style-4">
            <div className="container">
                <div className="banner-content">
                    <h1 className="title">Your One Stop EV Destination!</h1>
                    <p>Experience the future of transportation with our diverse range of electric vehicles, designed to redefine mobility and inspire sustainable living.</p>
                    <div>
                        <Link to={process.env.PUBLIC_URL + "/contact"} className="axil-btn btn-fill-primary btn-large">Contact Us</Link>
                    </div>
                </div>
                <div className="banner-thumbnail">
                    <div className="large-thumb">
                            <img src={process.env.PUBLIC_URL + "/images/landingg.png"} alt="Shape" />
                        
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BannerFour;