import React from 'react';
import SectionTitle from '../../elements/section-title/SectionTitle';

const Datas = [
    {
        id: 1,
        title: "Sustainability",
        para: " We prioritize promoting environmentally friendly transportation options to reduce carbon emissions and contribute to a cleaner planet."
    },
    {
        id: 2,
        title: "Innovation",
        para: "We are committed to staying at the forefront of technological advancements in electric vehicles, offering the latest models and cutting-edge solutions to our customers."
    },
    {
        id: 3,
        title: "Customer Satisfaction",
        para: "We strive to provide exceptional service and support to ensure a seamless and enjoyable experience for every customer, from browsing to purchase and beyond."
    },
    {
        id: 4,
        title: "Accessibility",
        para: "We believe in making electric vehicles accessible to all by offering a diverse range of models, price points, and financing options to suit various needs and budgets."
    },
    {
        id: 5,
        title: "Community Engagement",
        para: "We actively engage with our local communities to raise awareness about the benefits of electric mobility, organize events, and support initiatives that promote sustainable transportation practices."
    }
]




const AboutThree = () => {
    return (
        <div className="section section-padding bg-color-dark pb--80 pb_lg--40 pb_md--20">
            <div className="container">
                <SectionTitle 
                    subtitle="Our Values"
                    title="Guiding Principles of KOA"
                    description="At KOA Electric, our foundation is built upon a set of core values that guide us in everything we do. These values represent who we are, what we stand for, and how we strive to make a positive impact in the world of electric mobility."
                    textAlignment="heading-left heading-light-left mb--100"
                    textColor=""
                />

                <div className="row">
                    {Datas.map((data) => (
                        <div className="col-lg-4" key={data.id}>
                            <div className="about-quality">
                                <h3 className="sl-number">{data.id}</h3>
                                <h5 className="title">{data.title}</h5>
                                <p>{data.para}</p>
                            </div>
                        </div>
                    ))}

                </div>
            </div>
            <ul className="list-unstyled shape-group-10">
                <li className="shape shape-1"><img src={process.env.PUBLIC_URL + "/images/others/circle-1.png"} alt="Circle" /></li>
                <li className="shape shape-2"><img src={process.env.PUBLIC_URL + "/images/others/line-3.png"} alt="Circle" /></li>
                <li className="shape shape-3"><img src={process.env.PUBLIC_URL + "/images/others/bubble-5.png"} alt="Circle" /></li>
            </ul>
        </div>
    )
}

export default AboutThree;