import React from 'react';
import { Link } from 'react-router-dom';
import { FaAngleDown } from "react-icons/fa";


const Nav = () => {
    return (
        <nav className="mainmenu-nav">
            <ul className="mainmenu">
            <li><Link to={"/"}>Home</Link></li>
            <li><Link to={"/about-us"}>About Us</Link></li>
            <li href="franchise"><Link to={"/"}>Franchise</Link></li>
            <li><Link to={"/contact"}>Contact Us</Link></li>
            </ul>
        </nav>
    )
}

export default Nav;