import React from 'react';
import FooterOne from '../common/footer/FooterOne';
import HeaderOne from '../common/header/HeaderOne';
import SEO from '../common/SEO';
import BcrumbBannerOne from '../elements/breadcrumb/BcrumbBannerOne';
import CtaLayoutOne from '../component/cta/CtaLayoutOne';

import ProjectPropOne from '../component/project/itemProp/ProjectPropOne';
import ProjectData from "../data/project/ProjectData.json";
import ColorSwitcher from '../elements/switcher/ColorSwitcher';
import CaseStudyProp from '../component/casestudy/CaseStudyProp';
import SectionTitle from '../elements/section-title/SectionTitle';


const getProjectData = ProjectData;

const CaseStudy = () => {

    return (
        <>
        <SEO title="Franchise koa" />
        <ColorSwitcher />
            <main id="franchise" className="main-wrapper">
                <BcrumbBannerOne 
                title="Franchise Model"
                paragraph ="Our franchise model provides an exciting opportunity to capitalize on the growing demand for eco-friendly transportation solutions while offering customers a diverse range of products to choose from. As a franchisee, you'll have access to an exclusive lineup of top-tier brands in the electric vehicle industry. From high-performance electric motorcycles and stylish scooters to innovative three-wheelers, our curated selection ensures that customers have access to the best-in-class EVs available in the market."
                styleClass="thumbnail-3"
                mainThumb="/images/franc.png"
                />
                <div className="pt--250 pt_lg--200 pt_md--100 pt_sm--80 case-study-page-area">
                    <div className="container">
                        <CaseStudyProp />
                    </div>
                </div>
            </main>
        </>
    )
}

export default CaseStudy;