import React from 'react';
import { Link } from 'react-router-dom';
import CountUp from 'react-countup';
import TrackVisibility from 'react-on-screen';


const AboutFour = () => {
    return (
            <div style={{marginTop:"100px"}} className="section section-padding case-study-featured-area">
                <div className="container">
                    <div className="row">
                    <div className="col-xl-7 col-lg-6">
                        <div className="case-study-featured-thumb text-start">
                        <img src={process.env.PUBLIC_URL + "/images/KOA.jpeg"} alt="travel" />
                        </div>
                    </div>
                    <div className="col-xl-5 col-lg-6">
                        <div className="case-study-featured">
                        <div className="section-heading heading-left">
                            <span className="subtitle">Who we are</span>
                            <h2 className="title">KOA Electric: Your Destination for Premium EV Experience</h2>
                            <p>At KOA Electric, we're more than just a franchise model – we're pioneers in providing a comprehensive range of premium electric vehicle (EV) solutions all under one roof. With a commitment to sustainability, innovation, and customer satisfaction, we're revolutionizing the way people think about electric mobility.</p>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default AboutFour;