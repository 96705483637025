import React from 'react';
import SectionTitle from '../../elements/section-title/SectionTitle';
import ProjectPropTwo from './itemProp/ProjectPropTwo';
import ProjectData from "../../data/project/ProjectData.json";
import { Link } from 'react-router-dom';

const portfolioData = ProjectData;

const ProjectFour = () => {
    return (
        <div className="section section-padding-equal bg-color-dark">
            <div className="container">
            <SectionTitle 
                subtitle="Electric Vehicles"
                title="Variety Of Products"
                description="Discover an electrifying array of vehicles from leading manufacturers, each offering a unique blend of style, performance, and sustainability in our diverse showroom sections."
                textAlignment="heading-light-left mb--90"
                textColor=""
            />
            <div className="project-add-banner">
                <div className="content">
                    <span className="subtitle">Electric Vehicle MarketPlace!</span>
                    <h3 className="title">Your Gateway to the Multibrand Electric Vehicle Experience</h3>
                    <div className="brand-logo">
                        {/* <img src={process.env.PUBLIC_URL + "/images/project/godaddy.png"} alt="Godaddy" /> */}
                        <span className="subtitle">Explore Our Wide Array of Electric Vehicle Options</span>
                    </div>
                </div>
                <div className="thumbnail">
                    <img src={process.env.PUBLIC_URL + "/images/scooty.png"} alt="Mockup" />
                </div>
            </div>

            <div className="row row-45">
                {portfolioData.slice(12, 16).map((data) => (
                    <div className="col-md-6" key={data.id}>
                        <ProjectPropTwo projectStyle="project-style-2" portfolio={data}/>
                    </div>
                ))}

            </div>
            <div className="more-project-btn">
                <Link className="axil-btn btn-fill-white">MarketPlace Coming Soon</Link>
            </div>
        </div>
    </div>
    )
}

export default ProjectFour;